@font-face {
  font-family: 'BrownStd';
  src: local('BrownStd'), url(../fonts/BrownStd.woff) format('woff');
}

* {
  font-family: 'BrownStd', sans-serif;
}

.highlight-today .rdtToday,
.highlight-today .rdtToday:hover {
  background-color: #8898aa !important;
  color: white !important;
}

.rdtDay.rdtActive,
.rdtDay.rdtActive:hover {
  background-color: #7f7fc1 !important;
}

@media (min-width: 1200px) {
  .loginForm .container {
    max-width: 1250px;
  }
}

.loginForm .mt-n18 {
  margin-top: -20rem !important;
}

.authBackground,
.bg-info-bh {
  background: transparent
    linear-gradient(90deg, #3176a7 0%, #205072 49%, #153253 100%) 0% 0%
    no-repeat padding-box;
}

.bg-info-bh-dark {
  background: #205072 !important;
}

.bg-default-login {
  background-color: #ffffff;
}

.fill-default-login {
  fill: #f8f8fe;
}

.default-color,
.icon-color {
  color: #293b92;
}

.active.nav-item .nav-link-text {
  color: #3176a7;
}

.nav-link-text {
  color: black;
}

.icon-color-light {
  color: #8898aa;
}

.black-color {
  color: black;
}

.default-button-background,
.default-button-background:hover,
.btn-info:not(:disabled):not(.disabled):active {
  background-color: #3176a7;
  border-color: #3176a7;
}

.navbar-expand-end {
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.navbar-brand-img {
  max-height: 2.5rem !important;
}

.navbar-expand-space {
  justify-content: space-between !important;
}

.meditationImage {
  height: 3rem;
  width: 4rem;
  object-fit: cover;
  border-radius: 5%;
}

.meditationImageTable {
  height: 2.5rem;
  width: 2.5rem;
  object-fit: cover;
  border-radius: 10%;
}

.coverImage {
  height: 35px;
  width: 22px;
  object-fit: cover;
  border-radius: 5%;
}

.meditationImageDetail {
  height: 3.9rem;
  width: 4rem;
  object-fit: cover;
}

.rhap_additional-controls,
.rhap_volume-controls,
#rhap_current-time {
  display: none !important;
}

.rhap_container {
  background: #f9f9f9 !important;
  border: 1px solid #dee0e4 !important;
  border-radius: 5%;
  box-shadow: none !important;
}

.rhap_main {
  flex-direction: row-reverse !important;
}

.rhap_controls-section {
  margin-top: 0 !important;
  flex: none !important;
}

.rhap_progress-indicator {
  background: white !important;
  border: 1px solid black !important;
  height: 15px !important;
  width: 15px !important;
  top: -5px !important;
  margin-left: -7px !important;
}

.rhap_progress-filled {
  background-color: #3a3a73 !important;
}

.playIcon {
  color: #3a3a73;
  font-size: small;
}

.rhap_main-controls button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
}

.rhap_total-time {
  font-size: small !important;
}

.headingColor {
  color: #3b3b3b;
  font-weight: normal;
}

.headingColor-secondary {
  color: darkblue;
}

.active.nav-item {
  background-color: rgb(235, 242, 246, 0.5);
}

.dataTable {
  width: 100%;
  table-layout: fixed;
}

.dataTable td {
  padding: 0.5rem 1rem;
  color: #3b3b3b;
  font-size: 0.9rem;
  font-weight: lighter;
  text-align: center;
}

.is-invalid .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.is-invalid .ck.ck-toolbar,
.dropzone.invalid-dropzone .dz-message,
.is-invalid .ql-editor.ql-blank,
.rdt.is-invalid input,
.is-invalid .select2-selection.select2-selection--single,
.is-invalid .multiDropdown {
  border-color: #fb6340 !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.ck.ck-toolbar {
  border-color: #dee2e6 !important;
}

b,
strong {
  font-weight: bold !important;
}

.dashedBorder {
  border: 1px dashed #dee2e6;
}

.addBtn,
.addBtn:hover {
  color: white;
  background-color: #7f7fc1;
  border-color: #7f7fc1;
}

.dropzone .dz-button {
  color: black;
  background: white;
}

.dz-message {
  padding: 1rem;
}

.searchBox {
  width: 250px;
  border-color: #dee2e6;
}

.ql-snow .ql-tooltip::before,
.ql-action::after {
  content: '' !important;
}

.dz-preview {
  display: none;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 10000;
}

.spinner-border {
  display: block;
}

.spinner-container-admin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8ad;
  z-index: 10000;
}

.spinner {
  left: 55%;
  top: 50%;
  z-index: 1000;
  position: absolute;
  background-color: #205072;
  padding: 5px;
  border-radius: 50%;
}

.spinner-admin {
  left: 49%;
  top: 43%;
  z-index: 1000;
  position: absolute;
}

.successToast {
  background: #76d89c !important;
  border-color: #76d89c !important;
}

.updateToast {
  background: #3a3a73 !important;
  border-color: #3a3a73 !important;
}

.errorToast {
  background: red !important;
  border-color: red !important;
}

.successToast div,
.updateToast div,
.errorToast div {
  color: white;
  font-weight: bold;
}

.w-5 {
  width: 5% !important;
}

.w-15 {
  width: 15% !important;
}

.w-10 {
  width: 10% !important;
}

.w-7 {
  width: 7% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-20 {
  width: 20% !important;
}

.w-45 {
  width: 45% !important;
}

.w-40 {
  width: 40% !important;
}

.w-65 {
  width: 65% !important;
}

.overflowStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mirror {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

p {
  color: black;
}

.moodModal select,
.pageEntry select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

.moodModal select + i.fa {
  float: right;
  margin-top: -30px;
  margin-right: 5px;
  pointer-events: none;
  background-color: #fff;
  padding-right: 5px;
}

.dropdownIcon {
  position: absolute;
  top: 45px;
  right: 34px;
}

.new-line {
  white-space: pre;
}

.rdtPicker .rdtTimeToggle,
.rdtPicker .rdtTimeToggle:hover {
  visibility: visible !important;
  background: none !important;
}

.rdtTimeToggle::before {
  visibility: hidden !important;
}

.datePicker-invalid input {
  border-color: #fb6340;
}

.url.form-control {
  color: #7f7fc1 !important;
  text-decoration: underline;
}

.search .rdtPicker {
  left: -88px !important;
}

.page-item.active .page-link {
  background-color: #3176a7;
  border-color: #3176a7;
}

.rdtCounters .rdtCounter:last-child .rdtCount {
  font-size: x-small !important;
}

.close {
  opacity: 1 !important;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 1 !important;
}

.pageEntry select {
  background-color: transparent;
}

.pageEntry select + i.fa {
  margin-left: -15px;
}

.text-xxs {
  font-size: xx-small;
}

.form-control[readonly] {
  background-color: transparent !important;
}

.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active {
  background-color: #f8f8fe;
  margin-right: 0;
  margin-left: 0;
  padding-left: 1.5rem;
}

.thead-bh {
  background-color: #f6f9fc;
}

.thead-bh th {
  font-size: 0.7rem !important;
  letter-spacing: 0.1rem !important;
  text-align: center;
}

.searchColor {
  color: #8898aa !important;
}

.form-control {
  color: black !important;
}

.tableURL {
  text-decoration: none;
  color: black;
}

.imageViewerClose {
  position: absolute;
  right: 25px;
  top: 10px;
}

.actionDropdown .dropdown-menu .dropdown-item {
  font-size: 0.78rem;
}

.actionDropdown .dropdown-menu {
  min-width: 10rem;
}

.bt_logo {
  width: 11rem;
  object-fit: contain;
}

.focused .form-control,
.select2-container .select2-selection--single:focus,
.form-control:focus,
.react-tagsinput--focused {
  border-color: #3176a7 !important;
}

.react-tagsinput-tag {
  border: none;
  background-color: transparent !important;
  border-width: 1px 1px 1px 5px !important;
  border-style: solid !important;
  border-color: #e5e9ef !important;
  border-left-color: #3176a7 !important;
  border-radius: 5px !important;
  padding: 0rem 0.8rem !important;
  color: #556d7d !important;
  margin-bottom: 0 !important;
  color: #556d7d !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
}

.react-tagsinput {
  border: 1px solid #dee2e6 !important;
  border-radius: 0.25rem !important;
  min-height: calc(1.5em + 1rem + 5px) !important;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
}

.react-tagsinput--focused .react-tagsinput-input {
  font-size: 0.875rem !important;
  color: black !important;
  width: 100% !important;
  font-family: 'BrownStd', sans-serif !important;
}

.react-tagsinput-remove {
  color: black !important;
}

.forget-pass {
  color: #63839a;
}

.forget-pass-text {
  color: #adb5bd;
}

.back-login {
  color: #153253;
  font-weight: bolder;
}

.subhead {
  font-size: 1.5rem;
  font-weight: bold;
}

@media (min-width: 1200px) {
  .separator-skew {
    height: 74px;
  }
}

.signout {
  color: #d83443;
}

.adminImg {
  height: 3.5rem;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
  padding: 0.675rem 1.2rem !important;
}

.itemsPerPage {
  color: #556d7d;
}

.selectItemsPerPage {
  border-color: #e5e5e5;
}

.main-dark-color {
  color: #205072;
}

.action-bg {
  color: black !important;
}

.action-bg:hover,
.action-bg:focus {
  box-shadow: none !important;
  background-color: #f3f7fa !important;
}

.dataTable .btn-icon-only.btn-sm {
  width: 2.5rem;
  height: 2.5rem;
}

.backBtn {
  width: 6rem;
  object-fit: contain;
}

.backBtnLarge {
  width: 9rem;
  object-fit: contain;
}

.select2-container--open .select2-dropdown--below {
  box-shadow: 0px 10px 20px #a8a8a861;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: #c2d7e633 0% 0% no-repeat padding-box;
  color: #3176a7;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: black;
}

.linkText {
  color: #3176a7;
}

.multiDropdown {
  background-color: transparent !important;
  height: calc(1.5em + 1.25rem + 1px) !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 3px 2px rgb(233 236 239 / 5%) !important;
}

.selectedLabel {
  border-width: 1px 1px 1px 5px;
  border-style: solid;
  border-color: #e5e9ef;
  border-left-color: #3176a7;
  border-radius: 5px;
  width: fit-content;
  display: inline;
  padding: 0.5rem 1rem;
  color: #556d7d !important;
}

.css-7no60z-ButtonBase {
  display: flex !important;
}

.selectedClose {
  width: 1rem;
}

.css-9gakcf-option {
  background-color: transparent !important;
  color: #3176a7 !important;
}

.css-yt9ioa-option:active,
.css-1n7v3ny-option:active,
.css-1n7v3ny-option {
  background-color: transparent !important;
}

input[type='checkbox'] {
  accent-color: #3176a7;
}

.sidenav-toggler-line {
  background-color: #3176a7;
}

.css-14el2xx-placeholder {
  color: transparent !important;
}

.unitList {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 10px 40px 100px #00000026;
  border-radius: 10px;
  z-index: 100;
}

.unitsListClose {
  width: 1rem;
}

.menuDropdown {
  background-color: white;
  border-radius: 4;
  box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1),
    0 4px 11px hsla(218, 50%, 10%, 0.1);
  margin-top: 8;
  position: absolute;
  z-index: 2;
  width: 100%;
}

@media (max-width: 1550px) {
  .dataTable td {
    padding: 0.4rem 0.8rem;
    font-size: 0.7rem;
  }
  .thead-bh th {
    font-size: 0.57rem !important;
    letter-spacing: 0.1rem !important;
  }
}
